import { SMTPClient } from "smtp-client";
import { message } from "antd";

const s = new SMTPClient({
  host: "mail.gitexamerica.com",
  port: 465,
});

export const sendMail = (
  name = "",
  email = "",
  phone = "",
  text = "",
  state = "",
) => {
  (async function () {
    await s.connect();
    await s.greet({ hostname: "mail.gitexamerica.com" }); // runs EHLO command or HELO as a fallback
    await s.authLogin({ username: "info@gitex.us", password: "L9qNEDQZ3bTFmAT" }); // authenticates a user
    await s.mail({ from: email }); // runs MAIL FROM command
    await s.rcpt({ to: "chief@gitex.us" }); // runs RCPT TO command (run this multiple times to add more recii)
    await s.data(`Full Name: ${name}\nEmail Address: ${email}\nPhone Number: ${phone}\nCompany State: ${state}\nMessage: ${text}`); // runs DATA command and streams email source
    await s.quit(); // runs QUIT command
    message.success("Email successfully sent");
  }()).catch(message.error("Error while sending email"));
};
